











































































































import { Component, Vue } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
import { Rol } from '@/interfaces/account';

@Component
export default class AccountType extends Vue {
    async saveAccount(rol: Rol) {
        await agentModule.temporaryAccount({ rol, approved: 0 });
        this.$router.push({ name: 'MissingInformation' });
    }
}
